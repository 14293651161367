@import"https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&amp;display=swap";
@import"https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&amp;display=swap";

.row>* {
    position: relative;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px !important;
    }
}


#style-switcher {
    transition: all .4s;
    background: none repeat scroll 0 0 #f8f9fc;
    box-shadow: 0px 4px 12px rgba(24, 59, 86, .07);
    left: -189px;
    position: fixed;
    top: 35.5%;
    width: 189px;
    z-index: 999;
    padding: 10px 5px
}

#style-switcher .settings {
    background: #343a40;
    color: #fff;
    font-size: 20px;
    position: absolute;
    right: -45px;
    top: 0;
    height: 45px;
    width: 45px;
    line-height: 45px;
    text-align: center
}

ul.pattern {
    overflow: hidden;
    border-radius: 0px
}

ul.pattern li {
    float: left;
    margin: 2px
}

ul.pattern li a {
    display: block;
    height: 35px;
    width: 35px;
    border-radius: 30px;
    margin: 5px
}

ul.pattern .color1 {
    background-color: #0076a3
}

ul.pattern .color2 {
    background-color: #1f86ef
}

ul.pattern .color3 {
    background-color: #ffb60b
}

@media only screen and (max-width: 375px) {
    #style-switcher {
        display: none !important
    }
}

@media (min-width: 200px) and (max-width: 1024px) {
    .navbar-toggler:focus {
        box-shadow: none;
    }
}

@media (min-width: 200px) and (max-width: 1024px) {
    .navbar-toggler {
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

@media (max-width: 768px) {
    .navbar-custom {
        margin-top: 0px;
        padding: 10px 0px !important;
        background-color: #fff !important;
        box-shadow: 0px 3px 10px 0px rgb(24 59 86 / 8%);
        color: #fff !important;
    }
}

@media (min-width: 200px) and (max-width: 1024px) {
    .navbar-custom {
        margin-top: 0px;
        padding: 10px 0px !important;
        color: #fff !important;
        background: #fff;
    }
}

body[data-layout-mode=dark] .style-switcher h6 {
    color: #fff !important
}

/* body[data-layout-mode=dark] .home {
    background-image: url(../images/dark-bg.png) !important
} */

body[data-layout-mode=dark] .navbar .navbar-nav .nav-link.active {
    color: hsla(159, 35%, 45%, 1) !important
}

@media(min-width: 200px)and (max-width: 1024px) {
    .navbar-custom {
        margin-top: 0px;
        padding: 10px 0px !important;
        color: #fff !important;
        background: #fff
    }

    .navbar-custom .navbar-nav {
        margin-left: 0px
    }

    .navbar-custom .navbar-nav li.active {
        border-color: transparent
    }

    .navbar-custom .navbar-nav li a {
        transition: all .4s;
        margin: 6px;
        color: #183b56 !important;
        padding: 6px 0
    }

    .navbar-custom>.container {
        width: 90%
    }

    .home-title {
        font-size: 36px
    }

    .navbar-white .navbar-toggler {
        color: #183b56 !important
    }

    .navbar-custom .navbar-nav li.active a,
    .navbar-custom .navbar-nav li a:hover,
    .navbar-custom .navbar-nav li a:active {
        color: hsla(159, 35%, 45%, 1) !important
    }

    .navbar-toggler {
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 0px
    }

    .navbar-toggler:focus {
        box-shadow: none
    }

    .carousel-indicators {
        right: 0
    }

    .logo .logo-light {
        display: none
    }

    .logo .logo-dark {
        display: inline-block
    }
}

@media(min-width: 1200px) {
    .container {
        max-width: 1140px !important
    }
}

@media(max-width: 992px) {

    .home,
    .home-2,
    .home-4,
    .home-5,
    .home-6 {
        padding: 150px 0 50px 0
    }
}

@media(max-width: 1600px) {
    .request-image img {
        display: none !important
    }
}

@media(max-width: 768px) {
    .PageBackToWork-heading-itText {
        display: inline-block;
        font-size: 50px
    }
}

@media only screen and (min-width: 1600px) {
    .container-fluid {
        padding-right: 150px;
        padding-left: 150px
    }
}

.navbar {
    transition: all .5s ease;
    padding: 0;
    background-color: transparent !important
}

.navbar .logo .logo-light {
    display: none
}

.navbar .logo .logo-dark {
    display: inline-block
}

.navbar .navbar-nav .nav-link {
    color: #255F51;
    text-align: left;
    font-size: 13px;
    background-color: transparent;
    padding: 28px 25px;
    text-transform: uppercase;
    transition: all .3s ease;
    position: relative;
    font-weight: 700;

}




.contact-btn {
    padding-left: 20px;
}


.navbar .navbar-nav .nav-link:hover,
.navbar .navbar-nav .nav-link:active,
.navbar .navbar-nav .nav-link.active {
    color: hsla(159, 35%, 45%, 1) !important
}

.navbar .navbar-brand {
     display: contents;
    color: hsla(159, 35%, 45%, 1);
    font-weight: 600;
    font-size: 26px
}

.navbar .navbar-brand span {
    color: #000;
    font-weight: 800
}

.navbar .navbar-toggler {
    padding: 0px;
    border: none;
    font-size: 24px
}

.navbar .navbar-toggler:focus {
    box-shadow: none
}

.navbar .header-item {
    color: #183b56;
    transition: all .5s ease
}

@media(max-width: 992px) {
    .navbar-nav .dropdown .dropdown-menu {
        border: none;
        box-shadow: none;
        margin-top: 0
    }
}

@media(max-width: 576px) {
    .dropdown {
        position: static
    }
}

.navbar-light .nav-item .nav-link {
    color: rgba(255, 255, 255, .5) !important
}

.navbar-light .nav-item .nav-link:hover,
.navbar-light .nav-item .nav-link:focus,
.navbar-light .nav-item .nav-link.active {
    color: #fff !important
}

.navbar-light .btn {
    background-color: #183b56 !important;
    color: #fff;
    border: none !important
}

.navbar-light .logo .logo-light {
    display: block
}

.navbar-light .logo .logo-dark {
    display: none
}

@media(max-width: 992px) {
    .navbar-light .logo .logo-light {
        display: none
    }

    .navbar-light .logo .logo-dark {
        display: block
    }
}

.nav-sticky.navbar-light .nav-item .nav-link {
    color: hsla(159, 35%, 45%, 1) !important 
}

.nav-sticky.navbar-light .nav-item .nav-link:hover,
.nav-sticky.navbar-light .nav-item .nav-link:focus,
.nav-sticky.navbar-light .nav-item .nav-link.active {
    color: hsla(159, 35%, 45%, 1) !important
}

.nav-sticky.navbar-light .btn {
    background: linear-gradient(90deg, hsla(159, 35%, 45%, 1) 0%, hsla(176, 68%, 12%, 1) 100%) !important;
    color: #fff;
    border: none !important
}

.nav-sticky.navbar-light .logo .logo-light {
    display: none !important
}

.nav-sticky.navbar-light .logo .logo-dark {
    display: block !important
}

.nav-sticky.navbar {
    background: hsla(159, 35%, 45%, 1) !important;
    margin-top: 0px;
    box-shadow: 0px 30px 10px 0px rgb(24 59 86 / 20%);
}

.nav-sticky .nav-item .nav-link {
    color: hsla(159, 35%, 45%, 1) !important
}

@media(min-width: 992px)and (max-width: 1200px) {
    .navbar .navbar-nav .nav-link {
        padding: 25px 15px
    }
}

@media(max-width: 992px) {
    .navbar {
        padding: 14px 0;
        background-color: #fff !important
    }

    .navbar .navbar-nav .nav-item .nav-link {
        padding: 8px 15px;
        color: hsla(159, 35%, 45%, 1) !important
    }
}

body[data-layout-mode=dark] .navbar {
    background-color: transparent !important
}

body[data-layout-mode=dark] .nav-sticky {
    background-color: #255F51 !important
}

body[data-layout-mode=dark] .nav-sticky .nav-item .nav-link {
    color: #fff !important
}

body[data-layout-mode=dark] .nav-sticky .navbar-toggler i,
body[data-layout-mode=dark] .navbar .navbar-toggler i {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
    color: #fff !important
}

body[data-layout-mode=dark] .nav-sticky .navbar-nav .nav-link,
body[data-layout-mode=dark] .navbar .navbar-nav .nav-link {
    color: #fff
}

body[data-layout-mode=dark] .nav-sticky .navbar-nav .nav-link:hover,
body[data-layout-mode=dark] .nav-sticky .navbar-nav .nav-link.active,
body[data-layout-mode=dark] .navbar .navbar-nav .nav-link:hover,
body[data-layout-mode=dark] .navbar .navbar-nav .nav-link.active {
    color: hsla(159, 35%, 45%, 1) !important
}

body[data-layout-mode=dark] .nav-sticky .navbar-brand,
body[data-layout-mode=dark] .navbar .navbar-brand {
    color: hsla(159, 35%, 45%, 1) !important;
    font-weight: 600;
    font-size: 26px
}

body[data-layout-mode=dark] .nav-sticky .navbar-brand span,
body[data-layout-mode=dark] .navbar .navbar-brand span {
    color: #fff;
    font-weight: 800
}

body[data-layout-mode=dark] .navbar-toggler {
    color: #fff !important
}

body[data-layout-mode=dark] .navbar-brand {
    color: #0076a3 !important
}

body[data-layout-mode=dark] .navbar-brand span {
    color: #fff !important
}

body[data-layout-mode=dark] .navbar-brand .logo-dark {
    display: none
}

body[data-layout-mode=dark] .navbar-brand .logo-light {
    display: inline-block
}

body[data-layout-mode=dark] .nav-sticky.navbar-light .logo .logo-light {
    display: block !important
}

body[data-layout-mode=dark] .nav-sticky.navbar-light .logo .logo-dark {
    display: none !important
}

body[data-layout-mode=dark] .navbar-light .nav-item .nav-link {
    color: rgba(255, 255, 255, .5) !important
}

body[data-layout-mode=dark] .navbar-light .nav-item .nav-link:hover,
body[data-layout-mode=dark] .navbar-light .nav-item .nav-link:focus,
body[data-layout-mode=dark] .navbar-light .nav-item .nav-link.active {
    color: #fff !important
}

body[data-layout-mode=dark] .navbar-light .btn {
    background-color: #fff !important;
    color: #183b56;
    border: none !important
}

@media(max-width: 1025px) {
    body[data-layout-mode=dark] .navbar {
        background-color: #303841 !important
    }

    body[data-layout-mode=dark] .navbar .nav-item .nav-link {
        color: #fff !important
    }
}

@media(max-width: 992px) {
    .navbar {
        padding: 14px 0;
        background-color: #fff !important
    }

    .navbar-toggler i {
        color: #183b56
    }

    .navbar .dropdown .dropdown-menu .dropdown-item {
        padding: 10px 30px
    }
}

.text-primary {
    color: #0076a3 !important
}

.bg-primary {
    background-color: #0076a3 !important
}

.text-secondary {
    color: #979795 !important
}

.bg-secondary {
    background-color: #979795 !important
}

.text-success {
    color: #1d9b7b !important
}

.bg-success {
    background-color: #1d9b7b !important
}

.text-info {
    color: #1d9b7b  !important
}

.bg-info {
    background-color: #1d9b7b  !important
}

.text-warning {
    color: #ffb60b !important
}

.bg-warning {
    background-color: #ffb60b !important
}

.text-danger {
    color: #da3746 !important
}

.bg-danger {
    background-color: #da3746 !important
}

.text-light {
    color: #f8f9fc !important
}

.bg-light {
    background-color: #f8f9fc !important
}

.text-dark {
    color: #183b56 !important
}

.bg-dark {
    background-color: #183b56 !important
}

.text-muted {
    color: #838485 !important
}

.bg-muted {
    background-color: #838485 !important
}

.text-white {
    color: #fff !important
}

.bg-white {
    background-color: #fff !important
}

.text-purple {
    color: #766df4 !important
}

.bg-purple {
    background-color: #766df4 !important
}

.text-blue {
    color: #1f86ef !important
}

.bg-blue {
    background-color: #1f86ef !important
}

.text-orange {
    color: #e46e2a !important
}

.bg-orange {
    background-color: #e46e2a !important
}

.text-white-70 {
    color: rgba(255, 255, 255, .7) !important
}

.bg-white-70 {
    background-color: rgba(255, 255, 255, .7) !important
}

.section {
    padding-top: 30px;
    background-color: #F8F9FC;
    position: relative !important
}

.section2 {
    padding-top: 190px;
    padding-bottom: 120px;
    position: relative !important;
}

.para-p {
    max-width: 650px
}

@keyframes ani-bg {
    0% {
        transform: translateY(0px)
    }

    100% {
        transform: translateY(200px)
    }
}

.box-shadow {
    box-shadow: 0px 3px 10px 0px rgba(24, 59, 86, .08)
}

hr {
    border-top: 1px solid #838485;
    margin: 0
}

.lh-1 {
    letter-spacing: 1px
}

.f-12 {
    font-size: 12px
}

.f-14 {
    font-size: 14px
}

.f-15 {
    font-size: 15px
}

.f-16 {
    font-size: 16px
}

.f-18 {
    font-size: 18px
}

.f-20 {
    font-size: 20px
}

.f-22 {
    font-size: 22px
}

.f-24 {
    font-size: 23px
}

.f-30 {
    font-size: 30px
}

.f-35 {
    font-size: 35px
}

.f-40 {
    font-size: 40px
}

.f-50 {
    font-size: 50px
}

.f-100 {
    font-size: 100px
}

.avatar-xs {
    height: 2rem;
    width: 2rem;
    line-height: 2rem
}


.avatar-sm {
    height: 3rem;
    width: 3rem;
    line-height: 3rem
}

.avatar-md {
    height: 3.5rem;
    width: 3.5rem;
    line-height: 3.5rem
}

.avatar-lg {
    height: 4.5rem;
    width: 4.5rem
}

.avatar-xl {
    height: 6rem;
    width: 6rem
}

.avatar-xxl {
    height: 8rem;
    width: 8rem
}

.font-weight-500 {
    font-weight: 500
}

.font-weight-600 {
    font-weight: 600
}

.border-radius {
    border-radius: 6px !important
}

.btn:hover,
.btn:focus {
    box-shadow: none !important
}

.text-gradiant {
    background: -webkit-linear-gradient(#0076a3, #3c91b3);
    -webkit-text-fill-color: transparent
}

.bg-gradiant {
    background:linear-gradient(90deg, hsla(159, 35%, 45%, 1) 0%, hsla(176, 68%, 12%, 1) 100%);
    border: none !important;
    color: #fff !important
}
.bg-gradiant:hover{
    background:linear-gradient(90deg,  hsla(176, 68%, 12%, 1)  0%, hsla(159, 35%, 45%, 1) 100%);
}

.bg-overlay {
    background-color: rgba(74, 74, 74, .7);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}

.bg-overlay-gradiant {
    background: linear-gradient(80deg, #377d8f 0%, #0a4157 100%);
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: .9
}

.text-shadow {
    text-shadow: 1px 1px #fff
}

.modal .modal-dialog .btn-close {
    width: 26px;
    height: 26px;
    background-color: #fff;
    border-radius: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 3;
    opacity: 0;
    transition: all .3s ease-in-out
}

.modal .modal-dialog:hover .btn-close {
    opacity: .5
}

.para-width {
    max-width: 650px
}

@media(min-width: 992px) {
    .margin-top-120 {
        margin-top: 120px
    }
}

@media(max-width: 992px) {
    .section {
        padding-top: 80px;
        padding-bottom: 80px;
        position: relative !important
    }
}

body {
    font-family: "Roboto", sans-serif;
    overflow-x: hidden !important;
    color: #255F51
}

body.modal-open {
    padding-right: 0px !important
}

body.modal-open .navbar {
    padding-right: 0px !important
}

h1,
h2,
h3 {
    font-family: "Poppins", sans-serif;
    line-height: 1.4
}

h4,
h5,
h6 {
    font-weight: 600 !important;
    line-height: 1.4
}

.btn,
.btn:focus,
button,
button:focus {
    outline: none !important
}

a {
    text-decoration: none !important;
    outline: none
}

p {
    font-size: 16px;
    line-height: 25px
}

html {
    scroll-padding-top: 110;
    scroll-behavior: smooth
}

.row>* {
    position: relative
}

@media(max-width: 768px) {
    .navbar-custom {
        margin-top: 0px;
        padding: 10px 0px !important;
        background-color: #fff !important;
        box-shadow: 0px 3px 10px 0px rgba(24, 59, 86, .08);
        color: #fff !important
    }

    .navbar-toggler i {
        font-size: 24px;
        margin-top: 0px;
        margin-bottom: 0px;
        color: #183b56
    }

    .bg-home {
        height: auto
    }

    .nav-sticky.navbar-custom .navbar-nav li a {
        color: #fff !important
    }

    .nav-sticky .logo {
        color: #fff !important
    }

    .navbar-custom>.container {
        width: 100%
    }

    .clients-img {
        display: none
    }
}

.btn {
    margin-right: 27px;
    padding: 12px 30px;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.5px;
    transition: all 0.5s;
    border: 1px solid #255F51;
    overflow: hidden;
    position: relative;
}

@media only screen and (max-width: 600px) {
    .btn {
        margin-right: 12px;
        padding: 12px 30px;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0.5px;
        transition: all 0.5s;
        border: 1px solid #255F51;
        overflow: hidden;
        position: relative;
    }
  }

.contacas {
    margin-left: 100px;
}

.btn-sm {
    padding: 6px 20px;
    font-size: 12px
}

.bg-primary {
    background-color: #255F51 !important
}

.bg-soft-primary {
    background-color: rgba(0, 118, 163, .2) !important
}

.text-primary {
    color: #255F51 !important
}

.border-primary {
    border-color: #0076a3 !important
}

.icon-dual-primary {
    color: #0076a3;
    fill: rgba(0, 118, 163, .2)
}

.btn-primary {
    background:linear-gradient(90deg, hsla(159, 35%, 45%, 1) 0%, hsla(176, 68%, 12%, 1) 100%);
    color: #fff;
    border: 1px solid
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.focus,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary .open>.dropdown-toggle.btn-primary {
    background:linear-gradient(90deg,  hsla(176, 68%, 12%, 1) 0%,hsla(159, 35%, 45%, 1) 100%);
    box-shadow: none !important
}

.btn-outline-primary {
    color: #255F51;
    border-color: #255F51;
    border: 1px solid
}

.btn-outline-primary:hover,
.btn-outline-primary:focus,
.btn-outline-primary:active,
.btn-outline-primary.active,
.btn-outline-primary.focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary .open>.dropdown-toggle.btn-primary {
    background: #255F51;
    border-color: #255F51;
    box-shadow: none !important
}

.bg-secondary {
    background-color: #979795 !important
}

.bg-soft-secondary {
    background-color: rgba(151, 151, 149, .2) !important
}

.text-secondary {
    color: #979795 !important
}

.border-secondary {
    border-color: #979795 !important
}

.icon-dual-secondary {
    color: #979795;
    fill: rgba(151, 151, 149, .2)
}

.btn-secondary {
    background: #979795 !important;
    color: #fff;
    border-color: #979795 !important;
    border: 1px solid
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary.active,
.btn-secondary.focus,
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary .open>.dropdown-toggle.btn-primary {
    background: #8d8d8b;
    border-color: #8d8d8b;
    box-shadow: none !important
}

.btn-outline-secondary {
    color: #979795;
    border-color: #979795;
    border: 1px solid
}

.btn-outline-secondary:hover,
.btn-outline-secondary:focus,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.btn-outline-secondary.focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary .open>.dropdown-toggle.btn-primary {
    background: #979795;
    border-color: #979795;
    box-shadow: none !important
}

.bg-success {
    background-color: #1d9b7b !important
}

.bg-soft-success {
    background-color: rgba(29, 155, 123, .2) !important
}

.text-success {
    color: #1d9b7b !important
}

.border-success {
    border-color: #1d9b7b !important
}

.icon-dual-success {
    color: #1d9b7b;
    fill: rgba(29, 155, 123, .2)
}

.btn-success {
    background: #1d9b7b !important;
    color: #fff;
    border-color: #1d9b7b !important;
    border: 1px solid
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.btn-success.focus,
.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success .open>.dropdown-toggle.btn-primary {
    background: #1a8a6d;
    border-color: #1a8a6d;
    box-shadow: none !important
}

.btn-outline-success {
    color: #1d9b7b;
    border-color: #1d9b7b;
    border: 1px solid
}

.btn-outline-success:hover,
.btn-outline-success:focus,
.btn-outline-success:active,
.btn-outline-success.active,
.btn-outline-success.focus,
.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success .open>.dropdown-toggle.btn-primary {
    background: #1d9b7b;
    border-color: #1d9b7b;
    box-shadow: none !important
}

.bg-info {
    background-color: #24bee6 !important
}

.bg-soft-info {
    background-color: rgba(36, 190, 230, .2) !important
}

.text-info {
    color: #24bee6 !important
}

.border-info {
    border-color: #24bee6 !important
}

.icon-dual-info {
    color: #24bee6;
    fill: rgba(36, 190, 230, .2)
}

.btn-info {
    background: #24bee6 !important;
    color: #fff;
    border-color: #24bee6 !important;
    border: 1px solid
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.btn-info.focus,
.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info .open>.dropdown-toggle.btn-primary {
    background: #255F51;
    border-color: #255F51;
    box-shadow: none !important
}

.btn-outline-info {
    color: #24bee6;
    border-color: #24bee6;
    border: 1px solid
}

.btn-outline-info:hover,
.btn-outline-info:focus,
.btn-outline-info:active,
.btn-outline-info.active,
.btn-outline-info.focus,
.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info .open>.dropdown-toggle.btn-primary {
    background: #255F51;
    border-color: #255F51;
    box-shadow: none !important
}

.bg-warning {
    background-color: #ffb60b !important
}

.bg-soft-warning {
    background-color: rgba(255, 182, 11, .2) !important
}

.text-warning {
    color: #ffb60b !important
}

.border-warning {
    border-color: #ffb60b !important
}

.icon-dual-warning {
    color: #ffb60b;
    fill: rgba(255, 182, 11, .2)
}

.btn-warning {
    background: #ffb60b !important;
    color: #fff;
    border-color: #ffb60b !important;
    border: 1px solid
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.btn-warning.focus,
.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning .open>.dropdown-toggle.btn-primary {
    background: #f6ac00;
    border-color: #f6ac00;
    box-shadow: none !important
}

.btn-outline-warning {
    color: #ffb60b;
    border-color: #ffb60b;
    border: 1px solid
}

.btn-outline-warning:hover,
.btn-outline-warning:focus,
.btn-outline-warning:active,
.btn-outline-warning.active,
.btn-outline-warning.focus,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning .open>.dropdown-toggle.btn-primary {
    background: #ffb60b;
    border-color: #ffb60b;
    box-shadow: none !important
}

.bg-danger {
    background-color: #da3746 !important
}

.bg-soft-danger {
    background-color: rgba(218, 55, 70, .2) !important
}

.text-danger {
    color: #da3746 !important
}

.border-danger {
    border-color: #da3746 !important
}

.icon-dual-danger {
    color: #da3746;
    fill: rgba(218, 55, 70, .2)
}

.btn-danger {
    background: #da3746 !important;
    color: #fff;
    border-color: #da3746 !important;
    border: 1px solid
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.btn-danger.focus,
.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger .open>.dropdown-toggle.btn-primary {
    background: #d52737;
    border-color: #d52737;
    box-shadow: none !important
}

.btn-outline-danger {
    color: #da3746;
    border-color: #da3746;
    border: 1px solid
}

.btn-outline-danger:hover,
.btn-outline-danger:focus,
.btn-outline-danger:active,
.btn-outline-danger.active,
.btn-outline-danger.focus,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger .open>.dropdown-toggle.btn-primary {
    background: #da3746;
    border-color: #da3746;
    box-shadow: none !important
}

.bg-dark {
    background-color: #183b56 !important
}

.bg-soft-dark {
    background-color: rgba(24, 59, 86, .2) !important
}

.text-dark {
    color: #183b56 !important
}

.border-dark {
    border-color: #183b56 !important
}

.icon-dual-dark {
    color: #183b56;
    fill: rgba(24, 59, 86, .2)
}

.btn-dark {
    background: #183b56 !important;
    color: #fff;
    border-color: #183b56 !important;
    border: 1px solid
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active,
.btn-dark.active,
.btn-dark.focus,
.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark .open>.dropdown-toggle.btn-primary {
    background: #143046;
    border-color: #143046;
    box-shadow: none !important
}

.btn-outline-dark {
    color: #183b56;
    border-color: #183b56;
    border: 1px solid
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark:active,
.btn-outline-dark.active,
.btn-outline-dark.focus,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark .open>.dropdown-toggle.btn-primary {
    background: #183b56;
    border-color: #183b56;
    box-shadow: none !important
}

.bg-cyan {
    background-color: #00e6e6 !important
}

.bg-soft-cyan {
    background-color: rgba(0, 230, 230, .2) !important
}

.text-cyan {
    color: #00e6e6 !important
}

.border-cyan {
    border-color: #00e6e6 !important
}

.icon-dual-cyan {
    color: #00e6e6;
    fill: rgba(0, 230, 230, .2)
}

.btn-cyan {
    background: #00e6e6 !important;
    color: #fff;
    border-color: #00e6e6 !important;
    border: 1px solid
}

.btn-cyan:hover,
.btn-cyan:focus,
.btn-cyan:active,
.btn-cyan.active,
.btn-cyan.focus,
.btn-cyan:not(:disabled):not(.disabled):active,
.btn-cyan:not(:disabled):not(.disabled):active:focus,
.btn-cyan .open>.dropdown-toggle.btn-primary {
    background: #00d2d2;
    border-color: #00d2d2;
    box-shadow: none !important
}

.btn-outline-cyan {
    color: #00e6e6;
    border-color: #00e6e6;
    border: 1px solid
}

.btn-outline-cyan:hover,
.btn-outline-cyan:focus,
.btn-outline-cyan:active,
.btn-outline-cyan.active,
.btn-outline-cyan.focus,
.btn-outline-cyan:not(:disabled):not(.disabled):active,
.btn-outline-cyan:not(:disabled):not(.disabled):active:focus,
.btn-outline-cyan .open>.dropdown-toggle.btn-primary {
    background: #00e6e6;
    border-color: #00e6e6;
    box-shadow: none !important
}

.bg-blue {
    background-color: #1f86ef !important
}

.bg-soft-blue {
    background-color: rgba(31, 134, 239, .2) !important
}

.text-blue {
    color: #1f86ef !important
}

.border-blue {
    border-color: #1f86ef !important
}

.icon-dual-blue {
    color: #1f86ef;
    fill: rgba(31, 134, 239, .2)
}

.btn-blue {
    background: #1f86ef !important;
    color: #fff;
    border-color: #1f86ef !important;
    border: 1px solid
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active,
.btn-blue.focus,
.btn-blue:not(:disabled):not(.disabled):active,
.btn-blue:not(:disabled):not(.disabled):active:focus,
.btn-blue .open>.dropdown-toggle.btn-primary {
    background: #117ce9;
    border-color: #117ce9;
    box-shadow: none !important
}

.btn-outline-blue {
    color: #1f86ef;
    border-color: #1f86ef;
    border: 1px solid
}

.btn-outline-blue:hover,
.btn-outline-blue:focus,
.btn-outline-blue:active,
.btn-outline-blue.active,
.btn-outline-blue.focus,
.btn-outline-blue:not(:disabled):not(.disabled):active,
.btn-outline-blue:not(:disabled):not(.disabled):active:focus,
.btn-outline-blue .open>.dropdown-toggle.btn-primary {
    background: #1f86ef;
    border-color: #1f86ef;
    box-shadow: none !important
}

.bg-muted {
    background-color: #838485 !important
}

.bg-soft-muted {
    background-color: rgba(131, 132, 133, .2) !important
}

.text-muted {
    color: #838485 !important
}

.border-muted {
    border-color: #838485 !important
}

.icon-dual-muted {
    color: #838485;
    fill: rgba(131, 132, 133, .2)
}

.btn-muted {
    background: #838485 !important;
    color: #fff;
    border-color: #838485 !important;
    border: 1px solid
}

.btn-muted:hover,
.btn-muted:focus,
.btn-muted:active,
.btn-muted.active,
.btn-muted.focus,
.btn-muted:not(:disabled):not(.disabled):active,
.btn-muted:not(:disabled):not(.disabled):active:focus,
.btn-muted .open>.dropdown-toggle.btn-primary {
    background: #797a7b;
    border-color: #797a7b;
    box-shadow: none !important
}

.btn-outline-muted {
    color: #838485;
    border-color: #838485;
    border: 1px solid
}

.btn-outline-muted:hover,
.btn-outline-muted:focus,
.btn-outline-muted:active,
.btn-outline-muted.active,
.btn-outline-muted.focus,
.btn-outline-muted:not(:disabled):not(.disabled):active,
.btn-outline-muted:not(:disabled):not(.disabled):active:focus,
.btn-outline-muted .open>.dropdown-toggle.btn-primary {
    background: #838485;
    border-color: #838485;
    box-shadow: none !important
}

.bg-purple {
    background-color: #766df4 !important
}

.bg-soft-purple {
    background-color: rgba(118, 109, 244, .2) !important
}

.text-purple {
    color: #766df4 !important
}

.border-purple {
    border-color: #766df4 !important
}

.icon-dual-purple {
    color: #766df4;
    fill: rgba(118, 109, 244, .2)
}

.btn-purple {
    background: #766df4 !important;
    color: #fff;
    border-color: #766df4 !important;
    border: 1px solid
}

.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active,
.btn-purple.active,
.btn-purple.focus,
.btn-purple:not(:disabled):not(.disabled):active,
.btn-purple:not(:disabled):not(.disabled):active:focus,
.btn-purple .open>.dropdown-toggle.btn-primary {
    background: #645af3;
    border-color: #645af3;
    box-shadow: none !important
}

.btn-outline-purple {
    color: #766df4;
    border-color: #766df4;
    border: 1px solid
}

.btn-outline-purple:hover,
.btn-outline-purple:focus,
.btn-outline-purple:active,
.btn-outline-purple.active,
.btn-outline-purple.focus,
.btn-outline-purple:not(:disabled):not(.disabled):active,
.btn-outline-purple:not(:disabled):not(.disabled):active:focus,
.btn-outline-purple .open>.dropdown-toggle.btn-primary {
    background: #766df4;
    border-color: #766df4;
    box-shadow: none !important
}

.bg-pink {
    background-color: #f13e74 !important
}

.bg-soft-pink {
    background-color: rgba(241, 62, 116, .2) !important
}

.text-pink {
    color: #f13e74 !important
}

.border-pink {
    border-color: #f13e74 !important
}

.icon-dual-pink {
    color: #f13e74;
    fill: rgba(241, 62, 116, .2)
}

.btn-pink {
    background: #f13e74 !important;
    color: #fff;
    border-color: #f13e74 !important;
    border: 1px solid
}

.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active,
.btn-pink.active,
.btn-pink.focus,
.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink .open>.dropdown-toggle.btn-primary {
    background: #f02b66;
    border-color: #f02b66;
    box-shadow: none !important
}

.btn-outline-pink {
    color: #f13e74;
    border-color: #f13e74;
    border: 1px solid
}

.btn-outline-pink:hover,
.btn-outline-pink:focus,
.btn-outline-pink:active,
.btn-outline-pink.active,
.btn-outline-pink.focus,
.btn-outline-pink:not(:disabled):not(.disabled):active,
.btn-outline-pink:not(:disabled):not(.disabled):active:focus,
.btn-outline-pink .open>.dropdown-toggle.btn-primary {
    background: #f13e74;
    border-color: #f13e74;
    box-shadow: none !important
}

.bg-orange {
    background-color: #e46e2a !important
}

.bg-soft-orange {
    background-color: rgba(228, 110, 42, .2) !important
}

.text-orange {
    color: #e46e2a !important
}

.border-orange {
    border-color: #e46e2a !important
}

.icon-dual-orange {
    color: #e46e2a;
    fill: rgba(228, 110, 42, .2)
}

.btn-orange {
    background: #e46e2a !important;
    color: #fff;
    border-color: #e46e2a !important;
    border: 1px solid
}

.btn-orange:hover,
.btn-orange:focus,
.btn-orange:active,
.btn-orange.active,
.btn-orange.focus,
.btn-orange:not(:disabled):not(.disabled):active,
.btn-orange:not(:disabled):not(.disabled):active:focus,
.btn-orange .open>.dropdown-toggle.btn-primary {
    background: #de631c;
    border-color: #de631c;
    box-shadow: none !important
}

.btn-outline-orange {
    color: #e46e2a;
    border-color: #e46e2a;
    border: 1px solid
}

.btn-outline-orange:hover,
.btn-outline-orange:focus,
.btn-outline-orange:active,
.btn-outline-orange.active,
.btn-outline-orange.focus,
.btn-outline-orange:not(:disabled):not(.disabled):active,
.btn-outline-orange:not(:disabled):not(.disabled):active:focus,
.btn-outline-orange .open>.dropdown-toggle.btn-primary {
    background: #e46e2a;
    border-color: #e46e2a;
    box-shadow: none !important
}

.bg-light {
    background-color: #fff !important
}

.bg-soft-light {
    background-color: rgba(248, 249, 252, .2) !important
}

.text-light {
    color: #f8f9fc !important
}

.border-light {
    border-color: #f8f9fc !important
}

.icon-dual-light {
    color: #f8f9fc;
    fill: rgba(248, 249, 252, .2)
}

.btn-light {
    background: #f8f9fc !important;
    color: #fff;
    border-color: #f8f9fc !important;
    border: 1px solid
}

.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light.active,
.btn-light.focus,
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light .open>.dropdown-toggle.btn-primary {
    background: #eaedf6;
    border-color: #eaedf6;
    box-shadow: none !important
}

.btn-outline-light {
    color: #f8f9fc;
    border-color: #f8f9fc;
    border: 1px solid
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-outline-light.active,
.btn-outline-light.focus,
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light .open>.dropdown-toggle.btn-primary {
    background: #f8f9fc;
    border-color: #f8f9fc;
    box-shadow: none !important
}

.bg-white {
    background-color: #ffffff !important
}

.bg-soft-white {
    background-color: rgba(255, 255, 255, .2) !important
}

.text-white {
    color: #ffffff !important
}

.border-white {
    border-color: #ffffff !important
}

.icon-dual-white {
    color: #fff;
    fill: rgba(255, 255, 255, .2)
}

.btn-white {
    background: #ffffff !important;
    color: #fff;
    border-color: #ffffff !important;
    border: 1px solid
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.btn-white.focus,
.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled):active:focus,
.btn-white .open>.dropdown-toggle.btn-primary {
    background: #f5f5f5;
    border-color: #f5f5f5;
    box-shadow: none !important
}

.btn-outline-white {
    color: #ffffff;
    border-color: #ffffff;
    border: 1px solid
}

.btn-outline-white:hover,
.btn-outline-white:focus,
.btn-outline-white:active,
.btn-outline-white.active,
.btn-outline-white.focus,
.btn-outline-white:not(:disabled):not(.disabled):active,
.btn-outline-white:not(:disabled):not(.disabled):active:focus,
.btn-outline-white .open>.dropdown-toggle.btn-primary {
    background: #fff;
    border-color: #fff;
    box-shadow: none !important
}

.bg-white-70 {
    background-color: rgba(255, 255, 255, 0.7) !important
}

.bg-soft-white-70 {
    background-color: rgba(255, 255, 255, .2) !important
}

.text-white-70 {
    color: rgba(255, 255, 255, 0.7) !important
}

.border-white-70 {
    border-color: rgba(255, 255, 255, 0.7) !important
}

.icon-dual-white-70 {
    color: rgba(255, 255, 255, .7);
    fill: rgba(255, 255, 255, .2)
}

.btn-white-70 {
    background: rgba(255, 255, 255, 0.7) !important;
    color: #fff;
    border-color: rgba(255, 255, 255, 0.7) !important;
    border: 1px solid
}

.btn-white-70:hover,
.btn-white-70:focus,
.btn-white-70:active,
.btn-white-70.active,
.btn-white-70.focus,
.btn-white-70:not(:disabled):not(.disabled):active,
.btn-white-70:not(:disabled):not(.disabled):active:focus,
.btn-white-70 .open>.dropdown-toggle.btn-primary {
    background: rgba(245, 245, 245, .7);
    border-color: rgba(245, 245, 245, .7);
    box-shadow: none !important
}

.btn-outline-white-70 {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.7);
    border: 1px solid
}

.btn-outline-white-70:hover,
.btn-outline-white-70:focus,
.btn-outline-white-70:active,
.btn-outline-white-70.active,
.btn-outline-white-70.focus,
.btn-outline-white-70:not(:disabled):not(.disabled):active,
.btn-outline-white-70:not(:disabled):not(.disabled):active:focus,
.btn-outline-white-70 .open>.dropdown-toggle.btn-primary {
    background: rgba(255, 255, 255, .7);
    border-color: rgba(255, 255, 255, .7);
    box-shadow: none !important
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active {
    background: #f8f9fc !important;
    border: 1px solid rgba(131, 132, 133, .1) !important
}

.btn-dark {
    border: 1px solid #183b56 !important
}

.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active {
    background-color: hsla(159, 35%, 45%, 1) !important;
    border: 1px solid #183b56 !important;
    box-shadow: 0px 3px 10px 0px rgba(24, 59, 86, .08) !important
}

.btn-outline-dark {
    border: 1px solid #183b56;
    color: #183b56
}

.btn-outline-dark:hover,
.btn-outline-dark:focus,
.btn-outline-dark.active {
    color: #fff;
    background-color: #183b56
}

.btn {
    border-color: hsla(159, 35%, 45%, 1);
}

.card {
    background-color: #fff;
    border-color: #dee2e6;
    border-radius: 20px
}

.mode-btn {
    position: fixed;
    top: 28%;
    left: 0px;
    z-index: 1021;
    color: #fff;
    width: 45px;
    height: 45px;
    line-height: 45px;
    background-color: #255F51;
    border: 1px solid #255F51;
    font-size: 21px;
    text-align: center
}

.mode-btn .mode-dark {
    display: block;
    transition: all .5s ease
}

.mode-btn .mode-light {
    display: none;
    transition: all .5s ease
}

body[data-layout-mode=dark] #style-switcher {
    background-color: #242c34
}

body[data-layout-mode=dark] .mode-dark {
    display: none
}

body[data-layout-mode=dark] .mode-light {
    display: block
}

body[data-layout-mode=dark] .card {
    background-color: #283039 !important;
    border-color: #3d454e !important
}

body[data-layout-mode=dark] p {
    color: rgba(255, 255, 255, .3) !important
}

body[data-layout-mode=dark] .title h6 {
    color: #0076a3 !important
}

.home {
    min-height: 100vh;
    align-items: center;
    display: flex;
    /* background-image: url(../images/bg.png); */
    background-repeat: no-repeat;
    background-size: cover
}

.home .up-botton-text {
    position: absolute;
    top: 50%;
    left: -33%
}

.home .up-botton-text h5 {
    transform: rotate(90deg)
}

.home .smallphone-image {
    animation: ani-bg 3s infinite
}

@keyframes ani-bg {
    0% {
        transform: translateY(0px)
    }

    50% {
        transform: translateY(30px)
    }

    100% {
        transform: translateY(0px)
    }
}

.home-2 {
    min-height: 90vh;
    align-items: center;
    display: flex
}

.home-2 .home-title {
    font-weight: 500
}

.home-2 .phone-mockup {
    position: relative;
    z-index: 1;
    margin-bottom: -20%
}

.home-2 .video-preview {
    border-radius: 50px;
    box-shadow: 0 0 0 0 #0076a3;
    position: relative
}

.home-2 .video-preview .watch-video {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%) !important;
    left: 50%
}

.home-2 .video-preview .watch-video .video-play-icon i {
    box-shadow: 0 0 0 0 #0076a3;
    animation: pulse 2s infinite;
    font-size: 30px;
    line-height: 70px
}

@keyframes pulse {
    50% {
        box-shadow: 0 0 0 30px rgba(36, 194, 255, 0)
    }

    100% {
        box-shadow: 0 0 0 0 rgba(36, 194, 255, 0)
    }
}

.home-2 .background-line::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: rgba(255, 255, 255, .03);
    left: 20%;
    top: 0
}

.home-2 .background-line .line-rounded {
    position: absolute;
    height: 20px;
    width: 20px;
    border: 2px solid rgba(255, 255, 255, .03);
    border-radius: 50px;
    left: 20%;
    top: 50%;
    transform: translateX(-50%)
}

.home-2 .background-line-2::before {
    content: "";
    position: absolute;
    width: 2px;
    height: 100%;
    background-color: rgba(255, 255, 255, .03);
    right: 15%;
    top: 0
}

.home-2 .background-line-2 .line-rounded {
    position: absolute;
    height: 20px;
    width: 20px;
    border: 2px solid rgba(255, 255, 255, .03);
    background-color: rgba(255, 255, 255, .03);
    border-radius: 50px;
    right: 15%;
    top: 20%;
    transform: translateX(50%)
}

.home-2 .social-icon {
    position: absolute;
    left: 8%;
    bottom: 20%
}

.home-2 .social-icon i {
    font-size: 24px;
    color: rgba(255, 255, 255, .6)
}

.home-2 .social-icon .icon {
    transition: .5s
}

.home-2 .social-icon .icon:hover {
    transform: scale(1.2)
}

.home-3 .hero-img {
    min-height: 100vh;
    align-items: center;
    display: flex
}

.home-3 .container-fluid .side-img.img,
.home-3 .job-home-hero .container-fluid .side-img.img,
.home-3 .comingsoon .container-fluid .side-img.img {
    background: scroll center no-repeat;
    background-size: cover;
    position: absolute;
    height: 100vh;
    width: 48vw;
    top: 0;
    right: 0
}

.home-3 .side-img {
    min-height: 100vh;
    align-items: center;
    display: flex;
    z-index: 9;
    overflow: hidden;
    animation: image 2s infinite alternate
}

.home-3 .side-img .home-slider {
    padding: 140px 0
}

.home-3 .side-img .item {
    height: 500px;
    width: auto
}

.home-3 .side-img .tns-controls {
    position: absolute;
    z-index: 999;
    bottom: 0px;
    right: 0;
    margin-bottom: 10px
}

.home-3 .side-img .tns-controls button {
    margin: 0 10px;
    border-radius: 50px;
    height: 40px;
    width: 40px;
    background-color: rgba(255, 255, 255, .4);
    border: none
}



@media(max-width: 992px) {
    .home-3 .hero-img {
        min-height: 100% !important
    }

    .home-3 .tns-controls {
        display: none !important
    }

    .home-3 .side-img {
        position: relative !important;
        width: 100% !important;
        padding-top: 140px !important
    }
}

.home-4 {
    padding: 150px 0 0 0;
    align-items: center;
    display: flex;
    background-color: #f8f9fc;
    /* background-image: url(../images/home/home4-bg.png); */
    background-repeat: no-repeat;
    background-size: cover
}

.home-4 .bg4-image {
    top: 50px;
    z-index: 99
}

.home-4 .home-icon .icon-image {
    position: absolute
}

.home-4 .home-icon .chat {
    bottom: 30%;
    left: 0;
    animation: ani-bg 6s infinite
}

.home-4 .home-icon .heart {
    top: 30%;
    right: 20%;
    animation: ani-bg 7s infinite
}

.home-4 .home-icon .like {
    bottom: 20%;
    right: 5%;
    animation: ani-bg 10s infinite
}

.home-4 .home-icon .message {
    top: 0%;
    right: 0;
    animation: ani-bg 4s infinite
}

.home-4 .home-icon .twitter {
    top: 0%;
    left: 0;
    animation: ani-bg 3s infinite
}

.home-5 {
    min-height: 100vh;
    align-items: center;
    display: flex
}

.home-5 .more-profile .top-child {
    margin-left: -15px
}

.home-5 .more-profile .top-child img {
    border-radius: 50px;
    border: 3px solid #fff
}

.home-5 .more-profile .avatar-sm:last-child {
    text-align: center;
    background-color: #0076a3;
    border-radius: 50px;
    border: 3px solid #fff
}

.home-5 .more-profile .avatar-sm:last-child p {
    line-height: 2.5rem;
    font-weight: 500;
    color: #fff
}

.home-5 .form-group {
    position: relative
}

.home-5 .form-group .form-control {
    padding: 14px;
    border-radius: 5px
}

.home-5 .btn-big {
    padding: 15px 25px
}

.home-5 .waav {
    position: relative;
    -webkit-box-reflect: below -10px linear-gradient(transparent, rgba(0, 0, 0, 0.2));
    font-size: 20px;
    font-weight: 800
}

.home-5 .waav span {
    position: relative;
    display: inline-block;
    color: rgba(0, 118, 163, .5);
    text-transform: uppercase;
    animation: waav 1s infinite;
    animation-delay: calc(.1s * var(--a))
}

@keyframes waav {

    0%,
    40%,
    100% {
        transform: translateY(0)
    }

    20% {
        transform: translateY(-10px)
    }
}

.home-6 {
    min-height: 100vh;
    background: linear-gradient(to left, #f8f9fc 50%, #00c3ed 50%) !important;
    align-items: center;
    display: flex
}

.home-6 .testimonial-image {
    z-index: 2
}

.home-6 .tns-outer {
    position: relative
}

.home-6 .tns-outer .tns-controls {
    position: absolute;
    text-align: left;
    top: 50%;
    left: 34%;
    transform: translateY(50%);
    z-index: 999;
    margin-left: 20px
}

.home-6 .tns-outer .tns-controls button[data-controls=prev],
.home-6 .tns-outer .tns-controls button[data-controls=next] {
    color: #183b56;
    background: rgba(0, 118, 163, .3);
    border: none;
    border-radius: 50%;
    box-shadow: 0px 30px 10px 0px rgba(24, 59, 86, .08)
}

.home-6 .tns-outer .tns-controls button {
    margin: 0 0 0px 10px;
    border-radius: 50%;
    border: none;
    color: #0076a3;
    background: #0076a3;
    transition: all .3s;
    font-size: 20px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    bottom: 0;
    transform: translateY(50%);
    margin-top: 50px
}

.home-6 .tns-outer .tns-controls button:focus,
.home-6 .tns-outer .tns-outer .tns-controls button:hover,
.home-6 .tns-outer .tns-outer .tns-controls button:active {
    outline: none;
    background-color: #0076a3 !important;
    color: #fff
}

.home-6 .tns-outer .tns-controls:focus {
    outline: none !important
}

@media(max-width: 992px) {
    .home-6 {
        background: #f8f9fc !important
    }

    .home-6 .tns-outer .tns-controls {
        top: 50%;
        margin-left: 10px
    }
}

@media(max-width: 425px) {
    .home-6 .tns-outer .tns-controls {
        display: none !important
    }
}

/* body[data-layout-mode=dark] .home {
    background-image: url(../images/dark-bg.png);
    background-color: #303841 !important
} */

body[data-layout-mode=dark] .home .display-5 {
    color: #fff !important
}

body[data-layout-mode=dark] .home .up-botton-text h5 {
    color: rgba(255, 255, 255, .6)
}

body[data-layout-mode=dark] .home-2 {
    background-color: #343f4b !important
}

body[data-layout-mode=dark] .home-3 {
    background-color: #303841 !important
}

body[data-layout-mode=dark] .home-3 h1 {
    color: #fff
}

body[data-layout-mode=dark] .home-4 {
    background-color: #343f4b !important;
    color: #fff;
    background-image: none !important
}

body[data-layout-mode=dark] .home-5 {
    background: #343f4b !important;
    color: #fff !important
}

body[data-layout-mode=dark] .home-5 .more-profile .avatar-sm:last-child {
    text-align: center;
    background-color: #0076a3;
    border-radius: 50px;
    border: 3px solid #343f4b
}

body[data-layout-mode=dark] .home-5 .more-profile p {
    color: #fff !important
}

body[data-layout-mode=dark] .home-6 {
    background: linear-gradient(to left, #343f4b 50%, #303841 50%) !important;
    color: #fff !important
}

body[data-layout-mode=dark] .home-6 a {
    color: #fff !important
}

body[data-layout-mode=dark] .home-6 .tns-outer .tns-controls button[data-controls=prev],
body[data-layout-mode=dark] .home-6 .tns-outer .tns-controls button[data-controls=next] {
    color: #fff
}

.service .service-box {
    padding: 20px;
    border-radius: 20px;
    transition: all .3s
}

.service .service-box a {
    color: #183b56;
    transition: all .3s
}

.service .service-box .service-icon i {
    position: relative;
    top: 10px
}

.service .service-box:hover {
    box-shadow: 0px 3px 10px 0px rgba(24, 59, 86, .08);
    transition: all .3s;
    background-color: #fff
}

.service .service-box:hover a {
    color: hsla(159, 35%, 45%, 1);
}

.home2-service {
    padding-top: 250px
}

body[data-layout-mode=dark] .service {
    background-color: #303841 !important;
    color: #fff
}

body[data-layout-mode=dark] .service .service-box {
    background-color: #303841 !important
}

body[data-layout-mode=dark] .service .service-box h5 {
    color: #fff
}

body[data-layout-mode=dark] .service .service-box:hover {
    background-color: #343f4b !important
}

.features .nav .nav-item .nav-link {
    margin: 0 10px;
    border: 1px solid rgba(24, 59, 86, .5);
    color: #183b56
}

.features .nav .nav-item .nav-link.active {
    border: 1px solid hsla(159, 35%, 45%, 1);
    background: hsla(159, 35%, 45%, 1);
    color: #fff
}

.features .tab-content .tab-pane .features-item .icon {
    background-color: rgba(0, 118, 163, .1);
    color: hsla(159, 35%, 45%, 1);
}

body[data-layout-mode=dark] .features {
    background-color: #343f4b !important;
    color: #fff
}

body[data-layout-mode=dark] .nav .nav-item .nav-link {
    margin: 0 20px;
    border: 1px solid rgba(255, 255, 255, .5);
    padding: 10px 30px;
    color: #fff
}

body[data-layout-mode=dark] .features-box h6 {
    color: rgba(255, 255, 255, .7) !important
}

.testimonial .testi-slider {
    margin: 0 20px
}

.testimonial .testi-slider .item .testi-box {
    border-radius: 20px;
    box-shadow: 0 5px 13px rgba(60, 72, 88, .2);
    margin: 20px 0;
    cursor: grab
}

.testimonial .testi-slider .item .testi-box .social-icon i {
    color: rgba(255, 255, 255, .5)
}

.testimonial .tns-nav {
    text-align: center
}

.testimonial .tns-nav button {
    height: 12px;
    width: 12px;
    border-radius: 12px;
    margin: 0 8px;
    padding: 4px;
    background-color: rgba(0, 118, 163, .2);
    border: none
}

.testimonial .tns-nav .tns-nav-active {
    background-color: hsla(159, 35%, 45%, 1)
}

.app-slider {
    cursor: alias
}

.app-slider .swiper-container {
    padding: 18px 0 50px 0
}

.app-slider .swiper-container .fream-phone {
    position: absolute;
    z-index: 9;
    left: 50%;
    transform: translate(-50%);
    top: 0;
    height: auto;
    width: 230px
}

.app-slider .swiper-container .swiper-wrapper img {
    border-radius: 20px
}

.app-slider .swiper-container .swiper-pagination-bullet-active {
    opacity: 1;
    background: #0076a3
}

.app-slider img {
    width: 100%
}

@media(max-width: 1200px) {
    .app-slider .swiper-container {
        padding: 15px 0 50px 0
    }

    .app-slider .swiper-container .fream-phone {
        width: 180px
    }
}

@media(max-width: 992px) {
    .testimonial #testi-slider-iw {
        margin: -80px !important
    }

    .app-slider .swiper-container {
        padding: 15px 0 50px 0
    }

    .app-slider .swiper-container .fream-phone {
        width: 260px
    }
}

@media(max-width: 768px) {
    .fream-phone {
        display: none
    }
}

body[data-layout-mode=dark] .testimonial {
    background-color: #303841 !important;
    color: #fff
}

body[data-layout-mode=dark] .app-slider {
    background-color: #303841 !important;
    color: #fff !important
}

.pricing .price-item {
    border-radius: 10px;
    transition: all .5s
}

.pricing .price-item .price-up-box {
    background-color: rgba(0, 118, 163, .2)
}

.pricing .price-item .price-up-box.active {
    background-color: #183b56
}

.pricing .price-item .price-down-box button {
    background-color: #f8f9fc;
    border: 1px solid rgba(0, 118, 163, .2)
}

body[data-layout-mode=dark] .pricing {
    background-color: #343f4b !important;
    color: #fff
}

body[data-layout-mode=dark] .pricing h1 {
    color: #fff !important
}

body[data-layout-mode=dark] .pricing .topbar-header h6 {
    color: #fff !important
}

body[data-layout-mode=dark] .pricing .price-tag h2 {
    color: #fff !important
}

.team .team-box {
    position: relative
}

.team .team-box .team-image img {
    border-radius: 10px
}

.team .team-box .team-icon .social-icon a {
    color: #838485
}

.team .team-box .team-info {
    transform: rotate(270deg);
    top: 40%;
    left: -30px
}

body[data-layout-mode=dark] .team {
    background-color: #343f4b !important;
    color: #fff
}


.cta::after {
    content: "";
    position: absolute;
    bottom: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background: #fff;
    -ms-transform: matrix(1, -0.05, 0, 1, 0, 0);
    -webkit-transform: matrix(1, -0.05, 0, 1, 0, 0);
    transform: matrix(1, -0.05, 0, 1, 0, 0)
}

.cta::before {
    content: "";
    position: absolute;
    top: -50px;
    left: 0;
    width: 100%;
    height: 100px;
    background: #fff;
    transform: matrix(1, -0.05, 0, 1, 0, 0);
    z-index: 1
}

.cta .cta-phone-image {
    position: absolute;
    z-index: 999;
    top: -50%
}


body[data-layout-mode=dark] .cta::before {
    background: #343f4b !important
}

body[data-layout-mode=dark] .cta::after {
    background: #303841 !important
}

.contact .contact-map {
    position: relative;
    z-index: 999
}

.contact .map-shape::after {
    content: "";
    position: absolute;
    height: 350px;
    width: 200px;
    background-color: #0076a3;
    top: -5%;
    right: -10%;
    border-radius: 10px
}

.contact .contact-form {
    margin-bottom: 15px;
    box-shadow: none !important;
    height: 48px;
    padding-left: 20px;
    border: 1px solid #efefef;
    font-size: 15px;
    border-radius: 5px;
    z-index: 1
}

.contact .form-section {
    z-index: 1;
    position: relative;
    bottom: -100px
}

@media(max-width: 1200px) {
    .contact-map iframe {
        width: 450px !important
    }
}

@media(max-width: 992px) {
    .contact-map iframe {
        width: 600px !important
    }
}

@media(max-width: 768px) {
    .contact-map iframe {
        width: 400px !important
    }
}

@media(max-width: 460px) {
    .contact-map iframe {
        width: 300px !important
    }
}

body[data-layout-mode=dark] .contact {
    background-color: #303841 !important;
    color: #fff
}

.footer .bg-arrow {
    /* background-image: url(../images/home/arrow-bg.png) !important; */
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat
}

.footer .footer-icon a {
    border: 1px dashed rgba(0, 118, 163, .15)
}

.footer .footer-item li {
    padding-bottom: 8px
}

.footer .form-control {
    height: 56px;
    border: 1px solid rgba(24, 59, 86, .1)
}

.footer .form-control:hover,
.footer .form-control:focus {
    box-shadow: none !important
}

.footer .input-group-text {
    border: none !important
}

.footer .footer-center-border {
    border-bottom: 1px dashed rgba(24, 59, 86, .2) !important
}

.footer .footer-link li {
    margin-top: 5px
}

.footer .footer-link li a {
    color: rgba(255, 255, 255, .5) !important
}

body[data-layout-mode=dark] .footer {
    background-color: #343f4b !important;
    color: #fff !important
}



body[data-layout-mode=dark] .footer .form-control {
    height: 56px;
    border: 1px solid rgba(255, 255, 255, .5) !important
}

body[data-layout-mode=dark] .footer .input-group-text {
    border: 1px solid rgba(255, 255, 255, .02) !important
}

body[data-layout-mode=dark] .footer .footer-icon i {
    color: #fff !important
}

body[data-layout-mode=dark] .bottom-footer {
    background-color: #303841 !important
}
