.global_title {
    margin: 0 0 60px;
    padding: 0;
    font-size: 45px;
}


.specing17.row {
    
    margin: 0 -8.5px;
    margin-top: 0px;
    margin-right: -8.5px;
    margin-bottom: 0px;
    margin-left: -8.5px;
}

.aboutimg {
    color: black;
    border: none;
    vertical-align: middle;
    max-width: 20%;
}


.specing17.row>div {
    padding: 0 8.5px;
    margin-bottom: 17px;
      
}


.built_block {
    width: 100%;
    float: left;
    margin: 0;
    padding: 35px 25px 35px;
    height: 100%;
    background: #fff;
    border-radius: 10px;

    box-shadow: 0px 1px 9px 5px rgba(0, 0, 0, 0.3);
 }



.built_block:hover {
    
    background: linear-gradient(80deg, #46a5bd 0%, #116a8d 100%) !important;
  border-color: #116a8d !important;
  box-shadow: 0px 1px 9px 5px white;
}

.built_block figure {
    margin: 0 0 20px;
    padding: 0;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
}

.built_block .detail {
  
    font-family: "Poppins", sans-serif !important;
    margin: 0;
    padding: 0;
    word-spacing: -2px;
}

.built_block h3, .built_block p {
    padding: 0;
    /* transition: all .4s ease-in-out; */
    /* word-spacing: 1px; */
    /* word-spacing: -2px; */
}

.built_block:hover h3, .built_block:hover p {
    color:linear-gradient(80deg, #46a5bd 0%, #116a8d 100%) !important;
}


.bgg-dark {
    background-color: white;
    
}



  .built_block:hover figure {
    filter: contrast(0) brightness(100);
    /* color: #fff; */
}
  .built_block:hover h3 {
    color: #fff;
}
  .built_block:hover p {
    color: #fff;  
}

.color-one {
    color: #f75757;
}

.paragraf {
    font-family: "Poppins", sans-serif;
    font-size: 16px !important;
    line-height: 25px !important;
    word-spacing: -2px;
}

/* services */

