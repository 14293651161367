  @import url('https://fonts.googleapis.com/css2?family=Acme&display=swap');



  :root {
    --main-color: #255F51;
    --sec-color-1: #ff3838;
    --sec-opacity-1: hsla(0, 100%, 61%, 0.5);
    --sec-color-2: #1B1464;
    --sec-color-3: #FF9F1A;
    --main-transition: 0.4s ease;
  }
  .copyright a:hover{
    color: #fff !important;
  }

  .footesize:active{
    color: #fff;
  }
  .footesize:hover{
    color: #fff;
  }
  .mainheading{
    width: 98%;
    color: #fff;
    background-color: #14443D;
    margin-left: auto;
    margin-right: auto;
  }

  .imgowg{
    max-width: 100% !important;

  }


  .bg-color-gray {
    background-color: #ffffff;
  }

  .main-btn-1 {
    display: inline-block;
    padding: 8px 9px 8px 21px;
    background-color: var(--main-color);
    color: #fff;
    border-radius: 10px;
    transition: var(--main-transition);
    border: 2px solid var(--main-color);
  }

  .main-btn-1 i {
    margin-left: -8px;
    opacity: 0;
    transition: margin var(--main-transition), opacity var(--main-transition), padding var(--main-transition);
  }

  .main-btn-1:hover {
    color: var(--main-color);
    background-color: transparent;
  }


  .main-btn-1:hover i {
    margin-left: 2px;
    padding-right: 5px;
    opacity: 1;
  }

  .main-btn-2 {
    display: inline-block;
    padding: 8px 9px 8px 21px;
    background-color: transparent;
    color: var(--main-color);
    border-radius: 10px;
    border: 2px solid var(--main-color);
    transition: var(--main-transition);
  }

  .main-btn-2 i {
    margin-left: -8px;
    opacity: 0;
    transition: margin var(--main-transition), opacity var(--main-transition), padding var(--main-transition);
  }

  .main-btn-2:hover {
    color: #fff;
    background-color: var(--main-color);
  }

  .main-btn-2:hover i {
    margin-left: 2px;
    padding-right: 5px;
    opacity: 1;
  }

  .main-btn-3 {
    display: inline-block;
    padding: 8px 9px 8px 21px;
    background-color:  hsla(159, 35%, 45%, 1);
    color: #fff;
    border-radius: 10px;
    transition: var(--main-transition);
    border: 2px solid ;
  }

  .main-btn-3 i {
    margin-left: -8px;
    opacity: 0;
    transition: margin var(--main-transition), opacity var(--main-transition), padding var(--main-transition);
  }

  .main-btn-3:hover {
    color: #0076a3;
    background-color: transparent;
  }

  .main-btn-3:hover i {
    margin-left: 2px;
    padding-right: 5px;
    opacity: 1;
  }

  .main-btn-4 {
    display: inline-block;
    padding: 8px 9px 8px 21px;
    background-color: transparent;
    color: hsla(159, 35%, 45%, 1);
    border-radius: 10px;
    border: 2px solid #255F51;
    transition: var(--main-transition);
  }

  .main-btn-4 i {
    margin-left: -8px;
    opacity: 0;
    transition: margin var(--main-transition), opacity var(--main-transition), padding var(--main-transition);
  }

  .main-btn-4:hover {
    color: #fff;
    background-color: #255F51;
  }

  .main-btn-4:hover i {
    margin-left: 2px;
    padding-right: 5px;
    opacity: 1;
  }

  .sec-btn {
    display: inline-block;
    position: relative;
    padding: 5px 15px;
    background-color: var(--main-color);
    color: #fff !important;
    transition: var(--main-transition);
    overflow: hidden;
    z-index: 1;
  }

  .sec-btn::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%) scale(0);
    background-color: var(--sec-color-3);
    opacity: 0;
    transition: var(--main-transition);
    z-index: -1;
  }

  .sec-btn:hover::before {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 1;
  }


  /* Start Home1 */
  .home1 {
    position: relative;
    margin-top: 50px;
    /* min-height: 100vh; */
    display: flex;
    align-items: center;
    z-index: 10;
  }

  .home1>img.frame {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    z-index: -1;
  }

  .home1 .text {
    width: 100%;
    max-width: 600px;
  }

  .home1 .text h1 {
    margin-top: 70px;
    letter-spacing: 0px;
    padding: 10px 0;
    color: #255F51;
    font-weight: 900;
  }

  @media only screen and (max-width: 600px) {
    .home1 .text h1 {
      margin-top: -21px;
    
    }
  }

  .home1 .text p {
    color: #255F51;
    font-size: 16px;
    padding: 10px 0;
    text-align: justify;
    text-justify: inter-word;

  }

  .word-wrap .p {
    word-wrap: break-word;
  }

  .home1 .text .home-links {
    display: flex;
    padding: 10px 0;
  }



  .weprovide {
    font-size: 50px;
    letter-spacing: 2px;
    padding: 10px 0;
    color: black;
    font-family: 'Acme', sans-serif;
    font-weight: 900;
  }


  .image1 {
    margin-left:20%;
    margin-top: 25%;
    animation: MoveUpDown 5s linear infinite;
    animation-duration: 3s;
    animation-duration: 7s;
    animation-delay: 0s;
    animation-iteration-count: infinite;

    transform: translateY(0);
  }

  @keyframes MoveUpDown {

    0%,
    100% {
      transform: translateY(70px);
    }

    50% {
      transform: translateY(50px);
    }
  }



  @media only screen and (max-width: 600px) {
    .image1 {
      margin-left:0px !important;
      padding-bottom: 65px;
    }
  }



  .close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }

  .close:hover {
    opacity: 1;
  }

  .close:before,
  .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 20px;
    width: 2px;
    background-color: #333;
  }

  .close:before {
    transform: rotate(45deg);
  }

  .close:after {
    transform: rotate(-45deg);
  }



  /* contact */

  .ftco-section {
    padding: 7em 0;
  }

  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }

  *,
  *::before,
  *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  @media (min-width: 1200px) {
    .container {
      max-width: 1140px;
    }
  }

  @media (min-width: 992px) {
    .container {
      max-width: 960px;
    }
  }

  .wrapper {
    width: 100%;
    -webkit-box-shadow: 0px 21px 41px -13px rgb(0 0 0 / 18%);
    -moz-box-shadow: 0px 21px 41px -13px rgba(0, 0, 0, 0.18);
    box-shadow: 0px 21px 41px -13px rgb(0 0 0 / 18%);
  }

  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  .no-gutters>.col,
  .no-gutters>[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }

  .row07 {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

  .d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .contact-wrap {
    background: #fff;
  }

  .form-group {
    margin-bottom: 1rem;
  }

  @media (min-width: 768px) {
    .p-md-5 {
      padding: 3rem !important;
    }
  }

  @media (min-width: 768px) {
    .col-md-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media (min-width: 768px) {
    .col-md-6 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  @media (min-width: 768px) {
    .col-md-12 {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .form-control {
    height: 52px;
    background: #fff;
    color: #000;
    font-size: 14px;
    border-radius: 2px;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.1);
  }

  @media (min-width: 992px) {
    .info-wrap {
      /* margin-top: -20px; */
      /* margin-bottom: -20px; */
      /* border-radius: 5px; */
    }
  }

  .bg-primary {
    background:linear-gradient(90deg, hsla(159, 35%, 45%, 1) 0%, hsla(176, 68%, 12%, 1) 100%);
  }

  @media (min-width: 992px) {
    .p-lg-5 {
      padding: 3rem !important;
    }
  }


  .info-wrap h3 {
    color: white !important;
  }


  @media (min-width: 768px) {

    .mt-md-4,
    .my-md-4 {
      margin-top: 1.5rem !important;
    }
  }

  .mb-4,
  .my-4 {
    margin-bottom: 1.5rem !important;
  }


  .info-wrap .dbox {
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 25px;
  }

  .info-wrap .dbox .icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
  }


  .iconn {
    width: 90px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
  }

  .align-items-center {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }

  .justify-content-center {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }


  .info-wrap .dbox {
    width: 100%;
    color: rgba(255, 255, 255, 0.8);
    margin-bottom: 25px;
  }

  a:hover,
  a:focus {
    text-decoration: none !important;
    outline: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  a:hover {
    color: white;
    text-decoration: underline;
  }

  .info-wrap .dbox p span {
    font-weight: 600;
    color: #fff;
  }

  .fa {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }


  @media (min-width: 992px) {
    .info-wrap {
      margin-top: -20px;
      margin-bottom: -20px;
      border-radius: 5px;
    }
  }

  .text-07 {
    margin-top: 10px;
    margin-left: 10px;
  }

  .align-items-start {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }


  .submitting {
    float: left;
    width: 100%;
    padding: 10px 0;
    display: none;
    font-size: 16px;
    font-weight: bold;
  }


  .w-100 {
    width: 100% !important;
  }

  .error {
    color:#db2269;
    font-size: 0.80em;
    display: relative;
  }


  /* NoPage */


  .fundo {
    animation: scales 3s alternate infinite;
    transform-origin: center;
  }

  .pao-baixo {
    animation: rotatepao 14s cubic-bezier(.1, .49, .41, .97) infinite;
    transform-origin: center;
  }

  .pao-cima {
    animation: rotatepao 7s 1s cubic-bezier(.1, .49, .41, .97) infinite;
    transform-origin: center;
  }

  .olhos {
    animation: olhos 2s alternate infinite;
    transform-origin: center;
  }

  .left-sparks {
    animation: left-sparks 4s alternate infinite;
    transform-origin: 150px 156px;
  }

  .right-sparks {
    animation: left-sparks 4s alternate infinite;
    transform-origin: 310px 150px;
  }

  .olhos {
    animation: olhos 2s alternate infinite;
    transform-origin: center;
  }

  @keyframes scales {
    from {
      transform: scale(0.98)
    }

    to {
      transform: scale(1)
    }
  }

  @keyframes rotatepao {
    0% {
      transform: rotate(0deg)
    }

    50%,
    60% {
      transform: rotate(-20deg)
    }

    100% {
      transform: rotate(0deg)
    }

  }

  @keyframes olhos {
    0% {
      transform: rotateX(0deg);
    }

    100% {
      transform: rotateX(30deg);
    }
  }

  @keyframes left-sparks {
    0% {
      opacity: 0;
    }

  }


  .main {

    min-height: 600px;
    margin: 0px auto;
    width: auto;
    max-width: 460px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .path {
    stroke-dasharray: 300;
    stroke-dashoffset: 300;
    animation: dash 4s alternate infinite;
  }

  @keyframes dash {

    0%,
    30% {
      fill: 4B4B62;
      stroke-dashoffset: 0;
    }

    80%,
    100% {
      fill: transparent;
      stroke-dashoffset: -200;
    }


  }


  /* coutup */

  section#countup {
    font-size: 20px;
    background: #A7D4D2;
    text-align: center;
}

.ptb40 {
  padding: 40px 0;
}

section {
  position: relative;
  background: #fff;
}

.form-control2 {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
  border-radius: 6px;
}


@media only screen and (max-width: 425px){
.text h1 {
  text-align: -webkit-auto !important;
}
.avatar{
  display: none;

}

}


.card-career {
  border-color:#255F51;
  border-radius: 20px;
  box-shadow:10px 10px 12px;
}



.tsmonial {
  margin-left: 3rem !important;
  margin-top: 3rem !important;
}


@media only screen and (max-width: 600px) {
  .tsmonial {
    margin-left: 0rem !important;
  margin-top: 0rem !important;
  }
  
}


.uptop {
  position: fixed;
  bottom: 20px;
  left:55px;
  /* right: 50px; */
}

@media(max-width: 768px) {
  .uptop {
    display: none;
      position: fixed;
      bottom: 31px;
      right: 25px;
  } 
}


/* Hide the default radio button */
input[type="radio"] {
  display: none;
}

/* Style the label text */
label {
  font-weight: normal;
}

/* Add margin and padding to the options */
label div {
  border: 1px solid #255F51;
  border-radius: 10px;
  color: #255F51;
  cursor: pointer;
  display: block;
  font-size: 15px;
  height: 100%;
  margin: 0!important;
  opacity: .8;
  padding: 20px 19px;
  text-align: center;
  transition: none;
  width: 100%;
}

/* Highlight selected option */
input[type="radio"]:checked + div {
  background-color: #255F51;
  color: #fff;
  border-color: #255F51;
}

.success{
  color: green;
	font-weight: bold;
	font-size: 20px;
}

.danger {
  color: red;
  font-weight: bold;
	font-size: 20px;
}

.bg-gradiant2{
  border-radius: 13px 0;
  font-size: 14px;
  width: auto;

  background: linear-gradient(90deg, hsla(159, 35%, 45%, 1) 0%, hsla(176, 68%, 12%, 1) 100%);
    border: none !important;
    color: #fff !important
}
/* .btn.btn-info.btn-lg.model {
 
  border-radius: 13px 0;
  font-size: 14px;
  width: auto;

  background: linear-gradient(90deg, hsla(159, 35%, 45%, 1) 0%, hsla(176, 68%, 12%, 1) 100%);
    border: none !important;
    color: #fff !important;

} */


